import React, { useState } from 'react'
import { graphql } from 'gatsby'

import SEO from '~/components/seo'
import { Img, ContainerNarrow } from '~/utils/styles'

import RichText from '~/components/richText'

const TextPage = ({ data }) => {
  // console.log(data)
  const { title, content } = data.prismicTextPage.data
  console.log(title, content)

  return (
    <>
      <SEO title={title.text} />
      <ContainerNarrow>
        <RichText render={title.raw} />
        <RichText render={content.raw} />
      </ContainerNarrow>
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    prismicTextPage(uid: { eq: $handle }) {
      data {
        title {
          raw
          text
        }
        content {
          raw
        }
      }
    }
  }
`

export default TextPage
